<template>
  <section v-loading="loading" class="section-block">
    <SectionTitle :title="title" @edit="modal.edit = true" />
    <el-form label-position="left" label-width="200px">
      <el-form-item label="點數發放時機設定">
        <div class="secret-text collapse">
          <span>{{ displayData.timeing }}</span>
        </div>
      </el-form-item>
    </el-form>

    <MemberPointConfigEditModal
      v-if="modal.edit"
      :configData="configData"
      :source="source"
      :rewardType="rewardType"
      :hideOptions="hideOptions"
      @close="modal.edit = false"
      @refresh="refresh"
    />
  </section>
</template>

<script>
import { defineComponent, computed, reactive, ref, onMounted } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import MemberPointConfigEditModal from './MemberPointConfigEditModal.vue'
import { GetRewardActivityDelaySendSetting } from '@/api/rewardActivity'
import store from '@/store'

export default defineComponent({
  name: 'MemberPointConfigBlock',
  components: { SectionTitle, MemberPointConfigEditModal },
  props: {
    title: String,
    source: { type: String, default: '' },
    rewardType: { type: String, default: '' },
    hideOptions: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props) {
    const shopId = computed(() => store.getters.shop)
    const loading = ref(false)
    const configData = ref({})
    const displayData = computed(() => {
      const config = configData.value
      const mode = config.mode
      const days = config.delayDays
      let timeing = '-'
      if (mode === 'IMMEDIATELY') {
        timeing = '訂單完成後立即發放'
      }
      if (mode === 'DELAYED') {
        timeing = `訂單完成後 ${days} 天發放`
      }

      return { timeing }
    })
    const modal = reactive({
      edit: false,
    })

    const getRewardActivityDelaySendSetting = async () => {
      const [res, err] = await GetRewardActivityDelaySendSetting({
        shopId: shopId.value,
        source: props.source,
        rewardType: props.rewardType,
      })
      if (err) return window.$message.error(err)
      configData.value = res
    }

    const refresh = async () => {
      loading.value = true
      await getRewardActivityDelaySendSetting()
      loading.value = false
    }

    onMounted(async () => {
      refresh()
    })

    return { displayData, modal, configData, refresh, loading }
  },
})
</script>

<style lang="postcss" scoped>

</style>
